.input-raffle-maker {
  color: white;
  background: var(--background-color5);
  border: none;
  text-align: center;
  outline: solid 1px var(--background-color5);
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.info-icon {
  position: absolute;
  top: 10px;
  left: 15px;
  color: var(--accent-color3);
  font-size: x-large;
  cursor: pointer;
  transition: scale 0.1s ease-in-out;
}
.info-icon:hover {
  scale: 1.2;
  color: var(--accent-color2);
}

.raffle-info {
  display: inline;

  text-align: center;
}
.raffle-info-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1px;
  gap: 5px;
  color: var(--accent-color3);
}

.raffle-info span {
  color: red;
}

.raffle-info h6 {
  color: var(--accent-color2);
}
.input-name {
  width: 200px;
  margin: 5px;
  transition: outline 0.7s, width 0.5s ease-in-out;
}
.raffle-fee {
  border: var(--border2);
  border-radius: 5px;
  margin-bottom: 5px;
}
.input-name:focus {
  outline: solid 1px var(--accent-color);
  width: 250px;
  background: var(--background-color5);
}

.input-raffle-reward {
  display: inline-flex;
  gap: 10px;
  justify-content: center;
}

.input-reward:focus {
  outline: solid 1px var(--accent-color);
}
.input-reward {
  width: 150px;
}

.input-reward::-webkit-inner-spin-button,
.input-reward::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-reward {
  appearance: textfield;
}

.maker-reward {
  margin-bottom: 5px;
}

.maker-reward span {
  color: var(--background-color4);
}
.input-description {
  margin-top: 0;
  width: 80%;
  height: 60px;
  padding: 5px;
  resize: none;
}

.input-description:focus {
  outline: solid 1px var(--accent-color);
}

.raffle-type-picker {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin: 5px;
  margin-bottom: 10px;
}
.input-raffle-maker.input-time::-webkit-calendar-picker-indicator {
  filter: invert(1) brightness(0.8) sepia(100%) saturate(1000%)
    hue-rotate(180deg);

  cursor: pointer;
}
.input-raffle-buttons {
  width: 100%;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
}
/* Disable autocomplete background color change in Chrome */
.input-raffle-maker:-webkit-autofill,
.input-raffle-maker:-webkit-autofill:hover,
.input-raffle-maker:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px var(--background-color5) inset !important;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}

@media screen and (min-width: 1024px) {
  .input-raffle-maker.input-name {
    width: 250px;
  }

  .input-raffle-maker.input-name:focus {
    border: var(--background-color5);
    width: 400px;
  }
  .input-raffle-maker.input-reward {
    width: 200px;
  }
  .input-name {
    margin: 10px;
  }
}
