.welcome-site {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  user-select: none;
}

.welcome-logo {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 800px;
  max-height: auto;
}

.exp-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.exp-card-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: -1;
}

.experience-card {
  position: relative;
  overflow: hidden;
  margin: 10px;
  padding: 15px;
  width: 400px;
  min-height: 400px;
  background: var(--background-color2);
  border-radius: 10px;
  border: 2px solid transparent;
  text-align: center;
  filter: grayscale(0.9);
  place-content: center;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out,
    border-color 0.3s ease-in-out, border-width 0.3s ease-in-out;
}

.exp-card-text {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.4);
}

.exp-card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: auto;
  margin-top: 10px;
  padding: 0;
}
.exp-card-list li {
  background-color: var(--accent-color);
  border-radius: 10px;
  color: black;
  padding: 10px;
  flex-grow: 1;
}

.experience-card:hover {
  filter: grayscale(0.4);
  cursor: pointer;
  border-color: var(--accent-color2); /* Set the color of the border on hover */
  border-width: 2px; /* Ensure width is set to make it visible */
  animation: breathe 2s infinite ease-in-out;
}

.experience-card:hover .exp-card-list li {
  filter: saturate(3);
}
@keyframes breathe {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 1024px) {
  .welcome-logo {
    max-width: 90%;
  }
  .exp-cards-wrapper {
    justify-content: center;
  }
  .experience-card {
    width: 45%;
    filter: grayscale(0.1);
  }
}

@media only screen and (max-width: 768px) {
  .welcome-logo {
    max-width: 90%;
  }
  .exp-cards-wrapper {
    width: 90%;
    justify-content: center;
  }

  .experience-card {
    width: 95%;
    filter: grayscale(0.1);
  }
}
