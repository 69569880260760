.basic-site-container {
  display: block;
  position: relative;
  min-height: 100vh;
  user-select: none;
}

.basic-site-nav {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 150px;
  background: var(--background-color);
  padding: 0 20px;
  z-index: 10;
}

.nav-basic-socials {
  flex: 1;
  display: flex;
}

.nav-basic-more {
  flex: 1;
  display: flex;
}
.nav-basic-logo {
  flex: 2;
  display: flex;
  justify-content: center;
}

.nav-basic-logo img {
  width: 250px;
  height: auto;
}

.switch-button.basic {
  position: fixed;
  top: 30px;
  right: 30px;
  left: auto;
}

.basic-info {
  position: relative;

  width: 100%;
  height: auto;
  background: var(--background-color5);
  text-align: center;
  overflow: hidden;
}

.basic-banner {
  position: absolute;
  width: auto;
  height: 100%;
  left: 50%;
  transform: translateX(-50%); /* Center the image */
  display: block;
  object-fit: cover;

  /* Mask to create a fade effect */
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    black 30%,
    black 70%,
    rgba(0, 0, 0, 0)
  );
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    black 30%,
    black 70%,
    rgba(0, 0, 0, 0)
  );
}
.basic-info-text {
  position: relative;
  display: flex;
  width: 25%;
  flex-direction: column;
  top: 10%;
  left: 15%;
  padding: 10px;
  margin: 10px;
}

.basic-info-description {
  background: var(--background-color5);
  border: var(--border2);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.basic-info-description h4 {
  font-size: 200%;
  color: var(--accent-color2);
}
.basic-info-description h6 {
  font-size: 110%;
}

.basic-info-description img {
  width: 70%;
  padding: 10px;
  height: auto;
  animation: hover 3s infinite ease-in-out;
  cursor: pointer;
}

@keyframes hover {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.basic-info-links {
  display: flex;
  flex-direction: column;
  background: var(--background-color5);
  border: var(--border2);
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  align-content: center;
  padding: 10px;
}

.basic-info-links a {
  color: var(--accent-color);
  font-size: 4em;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basic-info-links a:hover {
  color: var(--accent-color4);
}

.basic-strips {
  position: relative;
  display: inline;
  text-align: center;
}

.basic-strips-title {
  position: relative;
  text-align: center;
  padding: 10px;
  background: var(--accent-color2);
}
.basic-strips {
  position: relative;

  display: flex;
  width: 100%;
  text-align: center;
}

.basic-footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  margin: auto;
  z-index: 100;
  height: 50px;
  background-color: var(--background-color);
}

.scroll-to-top {
  position: fixed;

  z-index: 100;
  bottom: 50px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: var(--accent-color);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out,
    transform 0.3s ease-in-out, background 0.3s ease-in-out;
}

.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.scroll-to-top:hover {
  background: var(--accent-color2);
}

@media screen and (max-width: 1024px) {
  .basic-site-nav {
    height: 100px;
    z-index: 100px;
    position: sticky;
    top: 0;
  }
  .basic-info-text {
    position: relative;
    display: flex;
    width: 65%;

    top: auto;
    left: auto;
    padding: 10px;
    margin: auto;
  }

  .nav-basic-logo img {
    width: 150px;
    height: auto;
  }
  .nav-basic-socials {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .basic-info-text {
    width: 95%;
  }
  .switch-button.basic {
    position: sticky;
    top: auto;
    right: auto;
    left: auto;
    width: 60px;
    height: 60px;
  }
}
