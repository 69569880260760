/* toggleswitch.css */
.toggle-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.toggle-switch {
  position: relative;
  width: 100px;
  height: 40px;
  background-color: rgb(198, 198, 198);
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.algos-active .toggle-switch {
  background-color: var(--background-color3);
}

.toggle-slider {
  position: absolute;
  left: 4px;
  top: 3px;
  width: 33px;
  height: 33px;
  background-color: var(--background-color2);
  border-radius: 50%;
  transition: left 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.algos-active .toggle-slider {
  left: calc(100% - 38px);
  background-color: var(--background-color4);
}

.image-container {
  position: relative;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cube-icon,
.algo-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.3s ease;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}
