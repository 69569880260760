.claim-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background-color: var(--background-color3);
  z-index: 100;
}

.claim-comic-trait-flex {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
  justify-content: center;
}

.claim-comic-trait {
  position: relative;
  max-width: 300px;
  width: calc(100% / 3);
  padding: 5px;
  background: var(--background-color6);
}

.claim-comic-trait img {
  width: 100%;
}

.claim-comic-trait.selected {
  border: 3px solid hsl(186, 100%, 81%, 0.7);
  border-radius: 5px;
  transition: border 0.3s ease;
}

.merge-step-2 {
  opacity: 0;

  transition: opacity 0.3s ease-in; /* Add smooth transitions */
  visibility: hidden; /* Start as hidden to avoid being in the layout */
}

.merge-step-2.ease-in {
  opacity: 1; /* Fade in */

  visibility: visible; /* Make visible */
}

.merge-step-2 h4 span {
  color: var(--accent-color4);
}

.trait-shop-img {
  display: flex;

  width: 50%;
  margin: auto;
}

.trait-shop-img :hover {
  border: var(--border1);
}

.trait-shop-img img {
  width: 100%;
}

@media (max-width: 1280px) and (max-height: 800px) {
  .claim-container .kioskmain {
    width: 60%;
  }
  .claim-container .kioskmain h4 {
    font-size: 1em;
  }
  .claim-container .kioskmain h5 {
    font-size: 1.3em;
  }
}

@media (max-width: 1024px) and (max-height: 600px) {
  .claim-container .kioskmain {
    width: 50%;
  }
  .claim-container .kioskmain h4 {
    font-size: 0.8em;
  }
  .claim-container .kioskmain h5 {
    font-size: 1em;
  }
}

@media (max-width: 760px) {
  .claim-container .kioskmain {
    width: 100%;
  }
  .claim-container .kioskmain h4 {
    font-size: 0.8em;
  }
  .claim-container .kioskmain h5 {
    font-size: 1em;
  }
  .claim-trait-info h6 {
    font-size: 0.8em;
  }
}
