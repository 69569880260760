.strip-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 15%;
  background-color: var(--background-color1);
  z-index: 20;
}

.stripmain {
  position: relative;
  width: 80%;
  height: auto;
  min-height: 100%;
  background: var(--background-color3);
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  justify-content: center;
}

.strip-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  gap: 10px;
}

.strip-thumbs {
  position: relative;
  width: calc(100% / 5 - 10px);
  padding: 5px;
  background: var(--background-color6);
}
.strip-thumbs:hover {
  transform: scale(1.03);
  cursor: pointer;
}
.strip-thumbs img {
  width: 100%;
  height: auto;
}
.auto-skeleton {
  width: 100%;
  height: 300px;
}
.strip-thumbs h5 {
  text-transform: capitalize;
}
/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.2s forwards;
}

/* Popup Image Container */
.popup-content {
  max-width: 90%;
  max-height: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.5);
  animation: popIn 0.3s forwards;
}

.popup-content img {
  width: 70%;
  height: auto;
  border-radius: 5px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@media screen and (max-width: 1280px) and (max-height: 800px) {
  .strip-thumbs {
    width: calc(100% / 4 - 10px);
  }
  .popup-content img {
    width: 60%;
    height: auto;
    border-radius: 5px;
  }
}

@media (max-width: 1024px) {
  .stripmain {
    width: 100%;
  }
  .strip-box {
    max-width: 100%;
  }
  .strip-thumbs {
    width: calc(100% / 3 - 10px);
  }
  .popup-content img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
}

@media screen and (max-width: 1024px) and (max-height: 600px) {
  .strip-thumbs {
    width: calc(100% / 4 - 10px);
  }
  .popup-content img {
    width: 60%;
    height: auto;
    border-radius: 5px;
  }
}

@media (max-width: 760px) {
  .stripmain {
    width: 100%;
  }
  .strip-box {
    max-width: 100%;
  }
  .strip-thumbs {
    width: calc(100% / 2 - 10px);
  }
  .popup-content img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
}
