@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-shadow: 0 0 0px rgba(255, 255, 255, 0.8);
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #3fd0e6;
}

.body {
  margin: 0;
  height: 100%;
  background: rgb(18, 1, 22);
  margin: 0;
  padding: 0;
  color: azure;
  font-family: "Oswald", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

:root {
  background: rgb(18, 1, 22);
  --accent-color: #92f4ffe6;
  --accent-color2: rgba(169, 93, 231, 0.9);
  --accent-color3: rgb(146, 244, 255);
  --accent-color4: rgb(255, 155, 15);
  --hover-color: #9df6ff33;
  --selected-color: #9df6ff99;
  --background-color: rgb(18, 1, 22);
  --background-color2: #282c34;
  --background-color3: rgba(18, 1, 22, 0.857);
  --background-color4: rgba(128, 128, 128, 0.9);
  --background-color5: rgba(35, 29, 39, 0.9);
  --background-color6: rgba(41, 27, 49, 1);
  --border1: solid 1.5px rgb(157, 246, 255, 0.9);
  --border2: solid 1px rgba(128, 128, 128, 0.9);
  --border3: solid 1.5px rgba(169, 93, 231, 0.9);
}

&::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

&::-webkit-scrollbar-thumb {
  background-color: #9df6ff; /* Set the color of the thumb */
  border-radius: 4px; /* Set the border radius of the thumb */
}

&::-webkit-scrollbar-track {
  background-color: #282c34; /* Set the color of the track */
}
